




























































import { Component, Vue } from "vue-property-decorator";
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';

import PlatformChart from "./202201_platform_chart.vue";
import USDChart from "./202201_USD_chart.vue";

Vue.component('PlatformChart', PlatformChart);
Vue.component('USDChart', USDChart);

@Component
export default class Plan extends Vue {
  public headers = [
    { text: '策略名稱', sortable: false, value: 'name', align: 'left'},
    { text: '平台', sortable: false, value: 'platform', align: 'left'},
    { text: '計價單位', sortable: false, value: 'unit', align: 'left'},
    { text: '價格敏感度', sortable: false, value: 'delta', align: 'left'},
    { text: '波動敏感度', sortable: false, value: 'vega', align: 'left'},
    { text: '時間敏感度', sortable: false, value: 'theta', align: 'left'},
  ];

  public strategies = [
    { name: 'Rabbit Lending + Farming', platform: 'ETH + BSC', unit: 'USD', delta: '-', vega: '-', theta: 'O'},
    { name: 'Rabbit Moon Strategy', platform: 'Solana', unit: 'USD', delta: '-', vega: 'XX', theta: 'OOO'},
    { name: 'Rabbit Star Strategy', platform: 'Solana', unit: 'USD', delta: '-', vega: 'XX', theta: 'OOO'},
    { name: 'Rabbit Moo Strategy', platform: 'Fantom', unit: 'USD', delta: 'O', vega: 'X', theta: 'OO'},
    { name: 'Rabbit Arbitrage', platform: 'FTX + Binance + BSC', unit: 'USD', delta: '-', vega: 'OO', theta: 'O'},
    { name: 'Rabbit Hedging', platform: 'FTX', unit: 'USD', delta: 'X', vega: 'O', theta: 'X'},
    { name: '總計 (加權平均)', platform: '-', unit: '-', delta: 'O', vega: 'X', theta: 'OO'},
  ]

  async mounted () {
    await dispatchGetConstants(this.$store);
  }

    // update version
  checkVersion () {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  created () {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize () {
    this.isMobile = window.innerWidth < 960;
  }
}
